import CryptoJS from 'crypto-js';
import { IMAGE_URL } from '@/constants/config';
import { escapeHtml } from '@/utils/helpers';

const generateImageHash = (string) => {
  let sha1 = CryptoJS.HmacSHA1(string, '624eK4R36tdZt823ZI411757F0Ej4JHlwl0691Dp');
  return CryptoJS.enc.Hex.stringify(sha1);
};

export const generateImageUrl = ({ size, type = 'png', quality = '80', imageUrl, gifToImage } = {}) => {
  const imageGenerateUrl = `${IMAGE_URL}/fit-in/`;
  const generatedHash = '/' + generateImageHash(size + '-' + type + '-' + quality + '-' + imageUrl) + '/';
  const setFilter = (filterName, value) => {
    return `/filters:${filterName}(${value})`;
  };
  const formatFilter = setFilter('format', type);
  const qualityFilter = gifToImage ? `/${quality}/` : setFilter('quality', quality);
  const url = `${imageGenerateUrl}${size}${formatFilter}${qualityFilter}${generatedHash}${imageUrl}`;
  return escapeHtml(url);
};

export const getFileExtension = (filename) => {
  return filename.split('.')[1];
};
