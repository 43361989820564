var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      { staticClass: "vx-navbar-wrapper" },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom",
            class: _vm.classObj,
            attrs: { color: _vm.navbarColor }
          },
          [
            _c("feather-icon", {
              staticClass: "sm:inline-flex xl:hidden cursor-pointer mr-1",
              attrs: { icon: "MenuIcon" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.showSidebar($event)
                }
              }
            }),
            [
              _c(
                "ul",
                { staticClass: "vx-navbar__starred-pages flex cursor-move" },
                [
                  _c(
                    "li",
                    { staticClass: "refresh-page" },
                    [
                      _c(
                        "vx-tooltip",
                        {
                          attrs: {
                            text: "Yenile",
                            position: "bottom",
                            delay: ".3s"
                          }
                        },
                        [
                          _c("vs-icon", {
                            staticClass: "p-2 cursor-pointer",
                            attrs: { svgClasses: "h-6 w-6", icon: "refresh" },
                            on: { click: _vm.triggerRefresh }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            _c("vs-spacer"),
            _c("LoggedInUser")
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }