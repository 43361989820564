<template>
  <div id="loggedInUser" v-if="user">
    <div class="the-navbar__user-meta flex items-center">
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ user.name }}</p>
        <small>Available</small>
      </div>
      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <div class="con-img ml-3">
          <img
            key="onlineImg"
            :src="getImageUrl(user.avatar)"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block object-cover"
          />
        </div>
        <vs-dropdown-menu class="vx-navbar-dropdown">
          <ul style="min-width: 9rem">
            <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
              <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
              <span class="ml-2" @click="logout">Çıkış Yap</span>
            </li>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
  </div>
</template>

<script>
import { generateImageUrl } from '@/utils/imageHash';
import UserService from '../../services/UserService';
export default {
  data() {
    return {
      user: null
    };
  },
  async created() {
    const userInfo = localStorage.getItem('userData');
    if (userInfo) {
      this.user = JSON.parse(userInfo);
    } else {
      this.user = await this.getUserInfo();
    }
  },
  methods: {
    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        type: 'png',
        quality: '80',
        imageUrl: image
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    },
    async getUserInfo() {
      const userInfo = await UserService.me();
      localStorage.setItem('userData', JSON.stringify(userInfo));
      return userInfo;
    },
    logout() {
      localStorage.clear();
      // this.$router.push('/pages/login');
      window.location = '/pages/login';
    }
  }
};
</script>

<style></style>
