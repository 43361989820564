var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c("div", { attrs: { id: "loggedInUser" } }, [
        _c(
          "div",
          { staticClass: "the-navbar__user-meta flex items-center" },
          [
            _c(
              "div",
              { staticClass: "text-right leading-tight hidden sm:block" },
              [
                _c("p", { staticClass: "font-semibold" }, [
                  _vm._v(_vm._s(_vm.user.name))
                ]),
                _c("small", [_vm._v("Available")])
              ]
            ),
            _c(
              "vs-dropdown",
              {
                staticClass: "cursor-pointer",
                attrs: { "vs-custom-content": "", "vs-trigger-click": "" }
              },
              [
                _c("div", { staticClass: "con-img ml-3" }, [
                  _c("img", {
                    key: "onlineImg",
                    staticClass:
                      "rounded-full shadow-md cursor-pointer block object-cover",
                    attrs: {
                      src: _vm.getImageUrl(_vm.user.avatar),
                      alt: "user-img",
                      width: "40",
                      height: "40"
                    }
                  })
                ]),
                _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                  _c("ul", { staticStyle: { "min-width": "9rem" } }, [
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                      },
                      [
                        _c("feather-icon", {
                          attrs: { icon: "LogOutIcon", svgClasses: "w-4 h-4" }
                        }),
                        _c(
                          "span",
                          { staticClass: "ml-2", on: { click: _vm.logout } },
                          [_vm._v("Çıkış Yap")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }